define("discourse/plugins/discourse-ratings/discourse/templates/components/rate-seller", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <DButton
      @action={{fn (action "rateSeller") topic}}
      @icon="far-star"
      @label={{"js.topic.rate_seller"}}
      class="btn-primary"
    />
  {{/if}}
  */
  {
    "id": "wl95bOXX",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,2],[[28,[37,3],[[30,0],\"rateSeller\"],null],[30,0,[\"topic\"]]],null],\"far-star\",\"js.topic.rate_seller\"]],null],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/discourse-ratings/discourse/templates/components/rate-seller.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\",\"fn\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/components/rate-seller.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});