define("discourse/plugins/discourse-ratings/discourse/templates/connectors/topic-above-posts/go-to-review", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{go-to-review topic=this.model}}
  */
  {
    "id": "YeJXPHYL",
    "block": "[[[1,[28,[35,0],null,[[\"topic\"],[[30,0,[\"model\"]]]]]]],[],false,[\"go-to-review\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/connectors/topic-above-posts/go-to-review.hbs",
    "isStrictMode": false
  });
});