define("discourse/plugins/discourse-ratings/discourse/templates/connectors/user-card-below-message-button/review-cta", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{cta-component user=this.user}}
  */
  {
    "id": "HgqEey9o",
    "block": "[[[1,[28,[35,0],null,[[\"user\"],[[30,0,[\"user\"]]]]]]],[],false,[\"cta-component\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/connectors/user-card-below-message-button/review-cta.hbs",
    "isStrictMode": false
  });
});