define("discourse/plugins/discourse-ratings/discourse/services/dialog", ["exports", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/services/dialog.js

  class DialogService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "isVisible", [_tracking.tracked], function () {
      return false;
    }))();
    #isVisible = (() => (dt7948.i(this, "isVisible"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "message", [_tracking.tracked], function () {
      return "";
    }))();
    #message = (() => (dt7948.i(this, "message"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "didConfirm", [_tracking.tracked], function () {
      return null;
    }))();
    #didConfirm = (() => (dt7948.i(this, "didConfirm"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showError", [_tracking.tracked], function () {
      return false;
    }))();
    #showError = (() => (dt7948.i(this, "showError"), void 0))();
    yesNoConfirm(_ref) {
      let {
        message,
        didConfirm
      } = _ref;
      this.message = message;
      this.didConfirm = didConfirm;
      this.isVisible = true;
      this.showError = false;
    }
    static #_5 = (() => dt7948.n(this.prototype, "yesNoConfirm", [_object.action]))();
    showErrorModal(_ref2) {
      let {
        message
      } = _ref2;
      this.message = message;
      this.showError = true;
      this.isVisible = true;
    }
    static #_6 = (() => dt7948.n(this.prototype, "showErrorModal", [_object.action]))();
    confirm() {
      if (this.didConfirm) {
        this.didConfirm();
      }
      this.close();
    }
    static #_7 = (() => dt7948.n(this.prototype, "confirm", [_object.action]))();
    close() {
      this.isVisible = false;
      this.message = "";
      this.didConfirm = null;
      this.showError = false;
    }
    static #_8 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
  }
  _exports.default = DialogService;
});