define("discourse/plugins/discourse-ratings/discourse/components/go-to-review", ["exports", "@ember/component", "@ember/service", "discourse/lib/url", "@ember/application"], function (_exports, _component, _service, _url, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "go-to-review-component",
    router: (0, _service.inject)(),
    didReceiveAttrs() {
      this.set("rateSeller", "topic.rate_seller");
      this.set("viewReputation", "topic.view_seller_reputation");
    },
    get shouldDisplay() {
      const topic = (0, _application.getOwner)(this).lookup("controller:topic");
      const categoryId = parseInt(this.siteSettings.seller_category);
      return topic.model.category_id === categoryId;
    },
    actions: {
      goToReview(topic) {
        const username = topic.title.match(/@(\w+)/)[1];
        return _url.default.routeTo(`/u/${username}/reviews`);
      }
    }
  });
});