define("discourse/plugins/discourse-ratings/discourse/components/cta-component", ["exports", "@ember/component", "@ember/service", "discourse/lib/url", "@ember/object"], function (_exports, _component, _service, _url, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "cta-component",
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    didReceiveAttrs() {
      this._super(...arguments);
      this.set("rateSeller", "topic.rate_seller");
      this.set("viewReputation", "topic.view_seller_reputation");
    },
    isCurrentUser: (0, _object.computed)("currentUser.username", "user.username", function () {
      return this.currentUser.username === this.user.username;
    }),
    actions: {
      rateSeller(user) {
        const container = Discourse.__container__;
        const Composer = require("discourse/models/composer").default;
        const composerController = container.lookup("controller:composer");
        const categoryId = parseInt(this.siteSettings.seller_category);
        const title = `Rating for @${user.username}`;
        composerController.open({
          action: Composer.CREATE_TOPIC,
          draftKey: Composer.DRAFT,
          categoryId,
          title
        });
      },
      rateBuyer(user) {
        const container = Discourse.__container__;
        const Composer = require("discourse/models/composer").default;
        const composerController = container.lookup("controller:composer");
        const categoryId = parseInt(this.siteSettings.buyer_category);
        const title = `Rating for @${user.username} as a buyer`;
        composerController.open({
          action: Composer.CREATE_TOPIC,
          draftKey: Composer.DRAFT,
          categoryId,
          title
        });
      },
      goToReview(user) {
        const username = user.username;
        return _url.default.routeTo(`/u/${username}/reviews`);
      }
    }
  });
});