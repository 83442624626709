define("discourse/plugins/discourse-ratings/discourse/components/rate-seller", ["exports", "@ember/component", "@ember/service", "@ember/application"], function (_exports, _component, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "rate-seller-component",
    router: (0, _service.inject)(),
    get shouldDisplay() {
      const topic = (0, _application.getOwner)(this).lookup("controller:topic");
      const categoryId = parseInt(this.siteSettings.seller_category);
      return topic.model.category_id === categoryId;
    },
    actions: {
      rateSeller(topic) {
        const container = Discourse.__container__;
        const Composer = require("discourse/models/composer").default;
        const composerController = container.lookup("controller:composer");
        const categoryId = parseInt(this.siteSettings.seller_category);
        const username = topic.title.match(/@(\w+)/)[1];
        const title = `Rating for @${username}`;
        composerController.open({
          action: Composer.CREATE_TOPIC,
          draftKey: Composer.DRAFT,
          categoryId,
          title
        });
      }
    }
  });
});