define("discourse/plugins/discourse-ratings/discourse/templates/components/review-star-rating", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.stars as |star|}}
    {{#if star.filled}}
      {{d-icon "star"}}
    {{else}}
      {{d-icon "far-star"}}
    {{/if}}
  {{/each}}
  */
  {
    "id": "AmXotmOr",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"stars\"]]],null]],null],null,[[[41,[30,1,[\"filled\"]],[[[1,\"    \"],[1,[28,[35,3],[\"star\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],[\"far-star\"],null]],[1,\"\\n\"]],[]]]],[1]],null]],[\"star\"],false,[\"each\",\"-track-array\",\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/components/review-star-rating.hbs",
    "isStrictMode": false
  });
});